import React from 'react';
import Box from '@mui/material/Box';

import ThemeProvider from '@spot-spotesg/providers/Theme.provider';

const PrintLayout = ({ children, orientation = 'portrait' }) => {
  return (
    <ThemeProvider>
      <Box minHeight="100vh" pt={10} pb={10}>
        <Box
          maxWidth={orientation === 'portrait' ? '210mm' : '287mm'}
          margin="0 auto"
          display="flex"
          flexDirection="column"
        >
          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default PrintLayout;
