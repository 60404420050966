import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import flow from 'lodash/flow';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  GlobalStyles,
} from '@mui/material';

import MapValidationComponent from '../components/MapDualValidation.component';
import LogoComponent from '@spot-spotesg/components/Logo.component';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import ROUTES from '@spot-spotesg/constants/routes.constant';

import PrintLayout from '../layouts/Print.layout';

import toDateUtil from '@spot-spotesg/utils/toDate.util';

import print from '../store/print';
import mapDualLeft from '../store/mapDual/mapDualLeft';
import mapDualRight from '../store/mapDual/mapDualRight';
import getStyleByYear from '@spot-spotesg/utils/getStyleByYear.util';
import analyse from '../store/analyse';

const BoxA4 = ({ children, loading = false }) => {
  return (
    <Box
      width="287mm"
      height="210mm"
      bgcolor="white"
      position="relative"
      className="boxa4"
    >
      {children}

      {loading && (
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={9999}
          bgcolor="white"
        >
          <CircularProgress />
        </Box>
      )}
    </Box>
  );
};

const AnalyticsValidationPrintScreen = () => {
  const dispatch = useDispatch();

  const history = useHistory();
  const { id: idRouter }: any = useParams();

  const selectorRedux = {
    data: useSelector(print.selector.selectState),
    analyseData: useSelector(analyse.selector.selectData),
  };

  const groupedAreasByYear = selectorRedux.data.areaSelected.reduce(
    (acc, area) => {
      const year = area.geojson.properties.year;
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(area);
      return acc;
    },
    {}
  );

  const dispatchRedux = {
    resetBoundsLeft: flow(mapDualLeft.action.resetBounds, dispatch),
    resetBoundsRight: flow(mapDualRight.action.resetBounds, dispatch),
  };

  const buttonBack = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        '@media print': {
          display: 'none',
        },
      }}
    >
      <Box display="flex" justifyContent="space-between" width="100%">
        <Button
          onClick={() => {
            if (history.length <= 2) {
              history.push(ROUTES.ANALYTICS.ID(idRouter));
            } else {
              history.goBack();
            }
          }}
          startIcon={<ArrowBackIcon />}
          sx={{ color: 'info.main' }}
        >
          Voltar
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            const tempTitle = document.title;
            document.title = `relatorio.pdf`;
            window.print();
            document.title = tempTitle;
          }}
          sx={{ color: 'white' }}
        >
          Imprimir
        </Button>
      </Box>
    </Box>
  );

  return (
    <>
      <GlobalStyles
        styles={{
          '@page': {
            margin: '16px',
            size: 'landscape',
            '-webkit-print-color-adjust': 'exact !important',
            printColorAdjust: 'exact !important',
          },
          '@media print': {
            'html, body': {
              height: '100%',
              overflow: 'hidden',
              '-webkit-print-color-adjust': 'exact !important',
              printColorAdjust: 'exact !important',
            },
          },
        }}
      />

      <PrintLayout orientation="landscape">
        <Box display="flex" flexDirection="column" gap={3}>
          {buttonBack}

          <BoxA4>
            <Box
              display="flex"
              flexDirection="column"
              p={'3mm'}
              gap={2.5}
              sx={{ '@media print': { p: 0, mt: -10 } }}
            >
              <Box position={'relative'} height="520px">
                <Box
                  width={'100%'}
                  height={'50px'}
                  alignItems={'center'}
                  position={'absolute'}
                  display="grid"
                  gridTemplateColumns="1fr 1fr"
                  gap={2}
                  zIndex={99999}
                >
                  <Box
                    display="flex"
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    gap={0}
                    p={1}
                  >
                    <img
                      src={process.env.PUBLIC_URL + '/img/rosa_dos_ventos.svg'}
                      alt="rosa dos ventos"
                      style={{
                        width: '60px',
                        aspectRatio: '1',
                      }}
                    />
                    <Typography
                      variant="caption"
                      fontSize="12px"
                      bgcolor={'#FFFFFFaa'}
                      borderRadius={'2px'}
                      fontFamily={'Arial'}
                      lineHeight={1.3}
                      p={0.5}
                    >
                      Data: {toDateUtil(selectorRedux.data?.left?.date)}
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent={'space-between'}
                    alignItems={'flex-start'}
                    gap={0}
                    p={1}
                  >
                    <img
                      src={process.env.PUBLIC_URL + '/img/rosa_dos_ventos.svg'}
                      alt="rosa dos ventos"
                      style={{
                        width: '60px',
                        aspectRatio: '1',
                      }}
                    />
                    <Typography
                      variant="caption"
                      fontSize="12px"
                      bgcolor={'#FFFFFFaa'}
                      borderRadius={'2px'}
                      p={0.5}
                      fontFamily={'Arial'}
                      lineHeight={1.3}
                    >
                      Data: {toDateUtil(selectorRedux.data?.right?.date)}
                    </Typography>
                  </Box>
                </Box>
                {selectorRedux.data?.geom && (
                  <MapValidationComponent
                    geom={selectorRedux.data?.geom}
                    areaSelected={selectorRedux.data?.areaSelected}
                    activeSync={false}
                    areas={
                      selectorRedux.analyseData?.data?.areas[0]?.results || []
                    }
                    printMode
                  />
                )}
              </Box>

              <Box
                display="grid"
                gridTemplateColumns="38% 1fr"
                alignItems={'start'}
                gap={6}
              >
                <Box
                  display="flex"
                  flexDirection={'column'}
                  alignItems={'flex-start'}
                  gap={1}
                >
                  <Box display="flex" flexDirection="column" gap={0}>
                    <Box>
                      <LogoComponent />
                    </Box>
                    <Typography
                      variant="caption"
                      fontSize="11px"
                      fontFamily={'Arial'}
                      lineHeight={1.3}
                      textAlign={'justify'}
                    >
                      DIREITOS RESERVADOS LEI 5772 DE 21/12/71 A SPOTSAT. <br />{' '}
                      Reprodução ou execução no seu todo ou parte deste projeto,
                      sem a prévia autorização de seu autor, ficará sujeita as
                      medidas legais cabíveis. As alterações deste projeto
                      eximem o autor de quaisquer responsabilidades posteriores.
                    </Typography>
                  </Box>
                  <Box display="flex" flexDirection="column" gap={1}>
                    <Typography
                      variant="caption"
                      fontSize="11px"
                      fontFamily={'Arial'}
                      lineHeight={1.3}
                      textAlign={'justify'}
                    >
                      Sistema de Referência de Coordenadas: Geográfico, SIRGAS
                      2000. EPSG: 4674 <br />
                      Base Cartográfica: Sentinel 2, Sensor MSI, Composição:
                      Bandas 4, 3, 2.
                      <br />
                      CAR - Cadastro Ambiental Rural; PRODES - 2008/2024; IBAMA
                      - Áreas Embargadas (2024); FUNAI: Terra Indígena (2024);
                      IBGE - Unidades de Conservação (2023)
                    </Typography>
                  </Box>
                </Box>

                <Box
                  display={'grid'}
                  gridTemplateColumns={'min-content 1fr'}
                  gap={6}
                >
                  <Box display="flex" flexDirection="column" gap={0.7}>
                    <Typography
                      variant="bodyxs"
                      pt={0.8}
                      mb={1.5}
                      whiteSpace={'nowrap'}
                      fontFamily={'Arial'}
                      fontSize={'14px'}
                      textAlign={'center'}
                    >
                      Legenda:
                    </Typography>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Box
                        width="24px"
                        height="16px"
                        borderRadius={'2px'}
                        bgcolor="rgba(51, 136, 255, 0.5)"
                        border="3px solid rgba(51, 136, 255, 1)"
                        sx={{
                          '@media print': {
                            WebkitPrintColorAdjust: 'exact',
                          },
                        }}
                      />
                      <Typography
                        variant="bodyxs"
                        whiteSpace={'nowrap'}
                        fontFamily={'Arial'}
                        fontSize={'12px'}
                      >
                        Limite da Propriedade
                      </Typography>
                    </Box>
                    <Typography
                      variant="bodyxs"
                      pt={1}
                      whiteSpace={'nowrap'}
                      fontFamily={'Arial'}
                      fontSize={'14px'}
                    >
                      Alertas PRODES
                    </Typography>
                    {Object.keys(groupedAreasByYear).map((year, index) => {
                      const legendStyle = getStyleByYear(year);

                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={1}
                          key={`${index}_${year}`}
                        >
                          <Box
                            width="24px"
                            height="16px"
                            bgcolor={legendStyle.bgOpacity}
                            border={`3px solid ${legendStyle.color}`}
                            borderRadius={'2px'}
                            sx={{
                              '@media print': {
                                WebkitPrintColorAdjust: 'exact',
                              },
                            }}
                          />
                          <Typography
                            variant="bodyxs"
                            fontFamily={'Arial'}
                            fontSize={'12px'}
                          >{`${year}`}</Typography>
                        </Box>
                      );
                    })}
                  </Box>

                  {selectorRedux.data?.conclusion && (
                    <Box display="flex" flexDirection="column" gap={1.5}>
                      <Typography
                        variant="caption"
                        fontFamily={'Arial'}
                        fontSize={'12px'}
                      >
                        Análise:
                      </Typography>
                      <Typography
                        variant="body2"
                        fontSize="12px"
                        fontFamily={'Arial'}
                        lineHeight={1.3}
                        textAlign={'justify'}
                      >
                        {selectorRedux.data?.conclusion}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </BoxA4>
        </Box>
      </PrintLayout>
    </>
  );
};

export default AnalyticsValidationPrintScreen;
