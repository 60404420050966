import React from 'react';
import L from 'leaflet';
import { uniqBy, countBy } from 'lodash';
import { Box, Button, ButtonGroup, Tooltip } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import {
  Adjust as AdjustIcon,
  CastConnected as CastConnectedIcon,
  Fullscreen as FullscreenIcon,
} from '@mui/icons-material';

import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import useEffectOnce from '@spot-spotesg/hooks/useEffectOnce.hook';

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.featuregroup.subgroup';

const MapComplianceComponent = ({
  geom,
  center = undefined,
  printMode = false,
  layers = [],
}) => {
  const [full, setFull] = React.useState<any>(false);

  const control: any = React.useRef(null);
  const map: any = React.useRef(null);
  const geoJsonRef: any = React.useRef(null);

  const handleCleanLayers = () => {
    map.current.eachLayer(function (layer) {
      if (
        layer?._url !== 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}'
      ) {
        map.current.removeLayer(layer);
      }
    });
  };

  useEffectOnce(() => {
    map.current = L.map(
      'map',
      printMode
        ? {
            zoomControl: false,
            dragging: false,
            scrollWheelZoom: false,
            doubleClickZoom: false,
            attributionControl: false,
          }
        : undefined
    );

    map.current.setView([-15.793889, -47.882778], 5);

    // map.current.on('moveend', () => handleChangeBounds());

    L.tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
      maxZoom: 19,
    }).addTo(map.current);
  });

  React.useEffect(() => {
    if (center) {
      map.current.setView(center, 10);

      // setTimeout(() => {
      //   handleChangeBounds();
      // }, 300);
    }
  }, [center]);

  React.useEffect(() => {
    map.current._onResize();
  }, [full]);

  React.useEffect(() => {
    if (geom) {
      if (control.current) {
        control.current.remove();
      }

      handleCleanLayers();

      control.current = L.control.layers(undefined, undefined, {
        collapsed: true,
      });

      const markers = (L as any).markerClusterGroup({
        chunkedLoading: true,
      });

      const geojsonOptions = {
        pointToLayer: function (feature, latLng) {
          return L.circleMarker(latLng, {
            radius: 5,
            color: 'black',
            fillColor: feature?.properties?.color,
            fillOpacity: 1,
            weight: 1,
          }).bindTooltip(feature?.properties?.description);
        },
      };

      const geomGeoJSON = L.geoJSON(geom, geojsonOptions);

      layers?.map((l: any) => {
        const subGroup = (L as any).featureGroup.subGroup(markers);
        const groupDifference = (L as any).featureGroup.subGroup(markers);

        control.current.addOverlay(
          subGroup,
          `<strong style="color: ${l?.color};">${l?.name}</strong>`
        );

        control.current.addOverlay(
          groupDifference,
          `<strong style="color: red;">Área sobreposta</strong>`
        );

        const geoJsonArr = l?.results?.map((gg) => gg.geojson);

        subGroup.addLayer(
          L.geoJSON(geoJsonArr, {
            style: function (feature) {
              return {
                color: feature?.properties?.color || 'black',
                fillOpacity: 0.7,
                stroke: false,
              };
            },
          })
        );

        groupDifference.addLayer(
          L.geoJSON(
            l?.results?.map((d) => d.geojson_difference),
            {
              style: function (feature) {
                return {
                  color: 'purple',
                  fillOpacity: 0.7,
                  stroke: false,
                };
              },
            }
          )
        );

        subGroup.addTo(map.current);
        groupDifference.addTo(map.current);
      });

      geomGeoJSON.addTo(map.current);

      if (!printMode) {
        control.current.addTo(map.current);
      }

      markers.addTo(map.current);

      geoJsonRef.current = geomGeoJSON;

      map.current.fitBounds(geomGeoJSON.getBounds());
    }
  }, [geom]);

  const centerMap = () => {
    map.current.fitBounds((geoJsonRef as any).current.getBounds());
  };

  const propsFull: any = {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 999,
  };

  const propsDefault = {
    position: 'relative',
    height: 'inherit',
    // zIndex: selectorRedux.loading ? -1 : 0,
  };

  return (
    <Box {...(full ? propsFull : propsDefault)}>
      <Box id="map" style={{ height: '100%' }} />

      {!printMode && (
        <Box position="absolute" zIndex={9999} top={'80px'} left="11px">
          <ButtonGroup
            orientation="vertical"
            variant="contained"
            sx={{
              '.MuiButtonGroup-grouped': {
                minWidth: 30,
                height: 30,
                ml: '2px',
              },
            }}
          >
            <Tooltip placement="right" title="Centralizar">
              <Button
                color="inherit"
                sx={{ width: 20, px: 0, minWidth: 0 }}
                onClick={() => centerMap()}
              >
                <GpsFixedIcon sx={{ fontSize: 20 }} />
              </Button>
            </Tooltip>
            <Tooltip placement="right" title={full ? 'Diminuir' : 'Expandir'}>
              <Button
                color="inherit"
                sx={{ width: 20, px: 0, minWidth: 0 }}
                onClick={() => setFull(!full)}
              >
                {!full && <FullscreenIcon />}
                {full && <FullscreenExitIcon />}
              </Button>
            </Tooltip>
          </ButtonGroup>
        </Box>
      )}
    </Box>
  );
};

export default MapComplianceComponent;
