import reducer from './profile.reducer';
import * as selector from './profile.selector';
import * as constant from './profile.constant';
import subscriber from './profile.subscriber';
import saga from './profile.saga';

export default {
  reducer,
  constant,
  selector,
  name: constant.NAME,
  subscriber,
  saga,
};
