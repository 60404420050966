import React from 'react';
import ReactDOMServer from 'react-dom/server';
import L from 'leaflet';
import { uniqBy, countBy } from 'lodash';
import { Box, GlobalStyles, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { flow } from 'lodash';

import {
  Adjust as AdjustIcon,
  CastConnected as CastConnectedIcon,
  Fullscreen as FullscreenIcon,
} from '@mui/icons-material';

import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';

import useEffectOnce from '@spot-spotesg/hooks/useEffectOnce.hook';

import mapDualRight from '../store/mapDual/mapDualRight';
import mapDualLeft from '../store/mapDual/mapDualLeft';

import 'leaflet.markercluster';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.featuregroup.subgroup';
import 'leaflet.sync';
import 'leaflet-easybutton';
import 'leaflet-draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import getStyleByYear from '@spot-spotesg/utils/getStyleByYear.util';

const MapValidationComponent = ({
  geom,
  areaSelected,
  activeSync,
  printMode = false,
  areas = [],
}) => {
  const dispatch = useDispatch();

  const map: any = React.useRef(null);
  const mapSecondary: any = React.useRef(null);
  const geoJsonLeft: any = React.useRef(null);
  const geoJsonRight: any = React.useRef(null);

  const dispatchRedux = {
    updateBoundsLeft: flow(mapDualLeft.action.updateBounds, dispatch),
    updateDrawLeft: flow(mapDualLeft.action.updateDraw, dispatch),
    updateDrawRight: flow(mapDualRight.action.updateDraw, dispatch),
    updateBoundsRight: flow(mapDualRight.action.updateBounds, dispatch),
  };

  const selectorRedux = {
    tileRight: useSelector(mapDualRight.selector.selectTile),
    tileLeft: useSelector(mapDualLeft.selector.selectTile),
    data: useSelector(mapDualRight.selector.selectData),
    dataLeft: useSelector(mapDualLeft.selector.selectData),
    loadingRight: useSelector(mapDualRight.selector.selectLoading),
    loadingLeft: useSelector(mapDualLeft.selector.selectLoading),
    boundsLeft: useSelector(mapDualLeft.selector.selectBounds),
    drawLeft: useSelector(mapDualLeft.selector.selectDraw),
    drawRight: useSelector(mapDualRight.selector.selectDraw),
    boundsRight: useSelector(mapDualRight.selector.selectBounds),
  };

  const geojsonOptions = {
    pointToLayer: function (feature, latLng) {
      return L.circleMarker(latLng, {
        radius: 5,
        color: 'black',
        fillColor: feature?.properties?.color,
        fillOpacity: 0.5,
        weight: 1,
      }).bindTooltip(feature?.properties?.description);
    },
  };

  useEffectOnce(() => {
    const center = [59.336, 5.967];

    map.current = L.map(
      'map',
      printMode
        ? {
            zoomControl: false,
            dragging: false,
            scrollWheelZoom: false,
            doubleClickZoom: false,
            attributionControl: false,
            worldCopyJump: true,
          }
        : {
            center: center as any,
            zoom: 14,
          }
    );

    if (!printMode) {
      map.current.attributionControl.setPrefix('');
    }

    mapSecondary.current = L.map(
      'mapSecondary',
      printMode
        ? {
            zoomControl: false,
            dragging: false,
            scrollWheelZoom: false,
            doubleClickZoom: false,
            attributionControl: false,
            worldCopyJump: true,
          }
        : undefined
    );

    if (!printMode) {
      mapSecondary.current.attributionControl.setPrefix('');
    }

    L.tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
      maxZoom: 19,
    }).addTo(map.current);

    L.tileLayer('https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}', {
      maxZoom: 19,
    }).addTo(mapSecondary.current);

    if (!printMode) {
      L.easyButton(
        ReactDOMServer.renderToString(<GpsFixedIcon />),
        function (btn, map) {
          map.fitBounds(geoJsonLeft.current.getBounds());
        }
      ).addTo(map.current);

      L.easyButton(
        ReactDOMServer.renderToString(<GpsFixedIcon />),
        function (btn, map) {
          map.fitBounds(geoJsonRight.current.getBounds());
        }
      ).addTo(mapSecondary.current);
    }

    if (printMode) {
      L.control.scale({ imperial: false }).addTo(map.current);
      L.control.scale({ imperial: false }).addTo(mapSecondary.current);
    }

    if (selectorRedux.tileLeft) {
      L.tileLayer(selectorRedux.tileLeft, {
        maxZoom: 19,
      }).addTo(map.current);
    }

    if (selectorRedux.tileRight) {
      L.tileLayer(selectorRedux.tileRight, {
        maxZoom: 19,
      }).addTo(mapSecondary.current);
    }

    if (!printMode) {
      mapSecondary.current.on('moveend', function (e) {
        const bounds = mapSecondary.current.getBounds();
        const zoom = mapSecondary.current.getZoom();

        dispatchRedux.updateBoundsRight({ ...bounds, zoom });
      });

      map.current.on('moveend', function (e) {
        const bounds = map.current.getBounds();
        const zoom = map.current.getZoom();

        dispatchRedux.updateBoundsLeft({ ...bounds, zoom });
      });

      const editableLayers = new L.FeatureGroup();
      const editableLayersSecondary = new L.FeatureGroup();
      map.current.addLayer(editableLayers);
      mapSecondary.current.addLayer(editableLayersSecondary);

      L.easyButton({
        states: [
          {
            stateName: 'arrow-up',
            icon: '<span class="star">&uarr;</span>',
            title: 'Enable markers',
            onClick: function () {
              map.current.on('click', function (e) {
                editableLayers.addLayer(
                  L.marker([e.latlng.lat, e.latlng.lng], {
                    icon: new L.Icon({
                      iconUrl: process.env.PUBLIC_URL + `/img/map_arrow_up.png`,
                      iconSize: [32, 64],
                      iconAnchor: [16, 0],
                      crossOrigin: true,
                    }),
                  })
                );

                dispatchRedux.updateDrawLeft(editableLayers);
                map.current.off('click');
              });
            },
          },
        ],
      }).addTo(map.current);

      L.easyButton({
        states: [
          {
            stateName: 'arrow-right',
            icon: '<span class="star">&rarr;</span>',
            title: 'Enable markers',
            onClick: function () {
              map.current.on('click', function (e) {
                editableLayers.addLayer(
                  L.marker([e.latlng.lat, e.latlng.lng], {
                    icon: new L.Icon({
                      iconUrl:
                        process.env.PUBLIC_URL + `/img/map_arrow_right.png`,
                      iconSize: [64, 32],
                      iconAnchor: [64, 16],
                      crossOrigin: true,
                    }),
                  })
                );

                dispatchRedux.updateDrawLeft(editableLayers);
                map.current.off('click');
              });
            },
          },
        ],
      }).addTo(map.current);

      L.easyButton({
        states: [
          {
            stateName: 'arrow-down',
            icon: '<span class="star">&darr;</span>',
            title: 'Enable markers',
            onClick: function () {
              map.current.on('click', function (e) {
                editableLayers.addLayer(
                  L.marker([e.latlng.lat, e.latlng.lng], {
                    icon: new L.Icon({
                      iconUrl:
                        process.env.PUBLIC_URL + `/img/map_arrow_down.png`,
                      iconSize: [32, 64],
                      iconAnchor: [16, 64],
                      crossOrigin: true,
                    }),
                  })
                );

                dispatchRedux.updateDrawLeft(editableLayers);
                map.current.off('click');
              });
            },
          },
        ],
      }).addTo(map.current);

      L.easyButton({
        states: [
          {
            stateName: 'arrow-left',
            icon: '<span class="star">&larr;</span>',
            title: 'Enable markers',
            onClick: function () {
              map.current.on('click', function (e) {
                editableLayers.addLayer(
                  L.marker([e.latlng.lat, e.latlng.lng], {
                    icon: new L.Icon({
                      iconUrl:
                        process.env.PUBLIC_URL + `/img/map_arrow_left.png`,
                      iconSize: [64, 32],
                      iconAnchor: [0, 16],
                      crossOrigin: true,
                    }),
                  })
                );

                dispatchRedux.updateDrawLeft(editableLayers);
                map.current.off('click');
              });
            },
          },
        ],
      }).addTo(map.current);

      L.easyButton({
        states: [
          {
            stateName: 'arrow-up',
            icon: '<span class="star">&uarr;</span>',
            title: 'Enable markers',
            onClick: function () {
              mapSecondary.current.on('click', function (e) {
                editableLayersSecondary.addLayer(
                  L.marker([e.latlng.lat, e.latlng.lng], {
                    icon: new L.Icon({
                      iconUrl: process.env.PUBLIC_URL + `/img/map_arrow_up.png`,
                      iconSize: [32, 64],
                      iconAnchor: [16, 0],
                      crossOrigin: true,
                    }),
                  })
                );

                dispatchRedux.updateDrawRight(editableLayersSecondary);
                mapSecondary.current.off('click');
              });
            },
          },
        ],
      }).addTo(mapSecondary.current);

      L.easyButton({
        states: [
          {
            stateName: 'arrow-right',
            icon: '<span class="star">&rarr;</span>',
            title: 'Enable markers',
            onClick: function () {
              mapSecondary.current.on('click', function (e) {
                editableLayersSecondary.addLayer(
                  L.marker([e.latlng.lat, e.latlng.lng], {
                    icon: new L.Icon({
                      iconUrl:
                        process.env.PUBLIC_URL + `/img/map_arrow_right.png`,
                      iconSize: [64, 32],
                      iconAnchor: [64, 16],
                      crossOrigin: true,
                    }),
                  })
                );

                dispatchRedux.updateDrawRight(editableLayersSecondary);
                mapSecondary.current.off('click');
              });
            },
          },
        ],
      }).addTo(mapSecondary.current);

      L.easyButton({
        states: [
          {
            stateName: 'arrow-down',
            icon: '<span class="star">&darr;</span>',
            title: 'Enable markers',
            onClick: function () {
              mapSecondary.current.on('click', function (e) {
                editableLayersSecondary.addLayer(
                  L.marker([e.latlng.lat, e.latlng.lng], {
                    icon: new L.Icon({
                      iconUrl:
                        process.env.PUBLIC_URL + `/img/map_arrow_down.png`,
                      iconSize: [32, 64],
                      iconAnchor: [16, 64],
                      crossOrigin: true,
                    }),
                  })
                );

                dispatchRedux.updateDrawRight(editableLayersSecondary);
                mapSecondary.current.off('click');
              });
            },
          },
        ],
      }).addTo(mapSecondary.current);

      L.easyButton({
        states: [
          {
            stateName: 'arrow-left',
            icon: '<span class="star">&larr;</span>',
            title: 'Enable markers',
            onClick: function () {
              mapSecondary.current.on('click', function (e) {
                editableLayersSecondary.addLayer(
                  L.marker([e.latlng.lat, e.latlng.lng], {
                    icon: new L.Icon({
                      iconUrl:
                        process.env.PUBLIC_URL + `/img/map_arrow_left.png`,
                      iconSize: [64, 32],
                      iconAnchor: [0, 16],
                      crossOrigin: true,
                    }),
                  })
                );

                dispatchRedux.updateDrawRight(editableLayersSecondary);
                mapSecondary.current.off('click');
              });
            },
          },
        ],
      }).addTo(mapSecondary.current);

      const drawControl = new (L as any).Control.Draw({
        position: 'topright',
        draw: {
          polyline: {
            shapeOptions: {
              color: 'red',
              weight: 10,
            },
          },
          polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
              color: '#e1e100', // Color the shape will turn when intersects
              message: "<strong>Oh snap!<strong> you can't draw that!", // Message that will show when intersect
            },
            shapeOptions: {
              color: 'red',
            },
          },
          circle: {
            shapeOptions: {
              color: 'red',
            },
          },
          circlemarker: {
            color: 'red',
          },
          rectangle: {
            shapeOptions: {
              color: 'red',
            },
          },
          marker: false,
        },
        edit: {
          featureGroup: editableLayers, //REQUIRED!!
        },
      });

      const drawControlSecondary = new (L as any).Control.Draw({
        position: 'topright',
        draw: {
          polyline: {
            shapeOptions: {
              color: 'red',
              weight: 10,
            },
          },
          polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
              color: '#e1e100', // Color the shape will turn when intersects
              message: "<strong>Oh snap!<strong> you can't draw that!", // Message that will show when intersect
            },
            shapeOptions: {
              color: 'red',
            },
          },
          circle: {
            shapeOptions: {
              color: 'red',
            },
          },
          circlemarker: {
            color: 'red',
          },
          rectangle: {
            shapeOptions: {
              color: 'red',
            },
          },
          marker: false,
        },
        edit: {
          featureGroup: editableLayersSecondary, //REQUIRED!!
        },
      });

      map.current.addControl(drawControl);
      mapSecondary.current.addControl(drawControlSecondary);

      map.current.on((L as any).Draw.Event.CREATED, function (e) {
        const layer = e.layer;

        const obs = prompt('Observação');

        if (obs) {
          layer.bindTooltip(obs, { permanent: true, interactive: true });
        }

        editableLayers.addLayer(layer);

        dispatchRedux.updateDrawLeft(editableLayers);
      });

      mapSecondary.current.on((L as any).Draw.Event.CREATED, function (e) {
        const layer = e.layer;

        const obs = prompt('Observação');

        if (obs) {
          layer.bindTooltip(obs, { permanent: true, interactive: true });
        }

        editableLayersSecondary.addLayer(layer);

        dispatchRedux.updateDrawRight(editableLayersSecondary);
      });
    }

    if (printMode) {
      if (selectorRedux.drawLeft) {
        const layers = (
          selectorRedux.drawLeft as L.FeatureGroup<any>
        ).getLayers();

        const markers = layers.filter((layer) => layer instanceof L.Marker);

        map.current.addLayer(selectorRedux.drawLeft);
        markers.forEach((marker) => {
          L.marker((marker as any).getLatLng(), marker.options).addTo(
            map.current
          );
        });
      }
      if (selectorRedux.drawRight) {
        const layers = (
          selectorRedux.drawRight as L.FeatureGroup<any>
        ).getLayers();

        const markers = layers.filter((layer) => layer instanceof L.Marker);

        mapSecondary.current.addLayer(selectorRedux.drawRight);
        markers.forEach((marker) => {
          L.marker((marker as any).getLatLng(), marker.options).addTo(
            mapSecondary.current
          );
        });
      }
    }
  });

  React.useEffect(() => {
    if (printMode) {
      return undefined;
    }

    if (activeSync) {
      map.current.sync(mapSecondary.current);
      mapSecondary.current.sync(map.current);
    } else {
      map.current.unsync(mapSecondary.current);
      mapSecondary.current.unsync(map.current);
    }
  }, [activeSync]);

  React.useEffect(() => {
    if (!areaSelected) {
      return undefined;
    }

    map.current.eachLayer(function (layer) {
      if (layer?.feature?.properties?.type === 'intersection') layer.remove();
    });
    mapSecondary.current.eachLayer(function (layer) {
      if (layer?.feature?.properties?.type === 'intersection') layer.remove();
    });

    areaSelected?.map((ar) => {
      L.geoJSON(ar?.geojson_difference, {
        style: function (feature) {
          const respectiveFeature: any[] = areas.filter(
            (area: any) => area?.geojson_difference?.id === feature?.id
          );

          const differenceYear =
            respectiveFeature.length > 0
              ? respectiveFeature[0]?.geojson?.properties.year
              : '0';

          return getStyleByYear(differenceYear);
        },
      }).addTo(map.current);

      L.geoJSON(ar?.geojson_difference, {
        style: function (feature) {
          const respectiveFeature: any[] = areas.filter(
            (area: any) => area?.geojson_difference?.id === feature?.id
          );

          const differenceYear =
            respectiveFeature.length > 0
              ? respectiveFeature[0]?.geojson?.properties.year
              : '0';

          return getStyleByYear(differenceYear);
        },
      }).addTo(mapSecondary.current);
    });
  }, [areaSelected]);

  React.useEffect(() => {
    if (!geom) {
      return undefined;
    }

    const geomGeoJSON = L.geoJSON(geom, geojsonOptions).addTo(map.current);
    const geomGeoJSONSecondary = L.geoJSON(geom, geojsonOptions).addTo(
      mapSecondary.current
    );

    geoJsonLeft.current = geomGeoJSON;
    geoJsonRight.current = geomGeoJSONSecondary;

    if (!printMode) {
      if (
        selectorRedux.boundsLeft?._southWest &&
        selectorRedux.boundsRight?._southWest
      ) {
        map.current.fitBounds(
          L.latLngBounds(
            selectorRedux.boundsLeft?._southWest,
            selectorRedux.boundsLeft?._northEast
          )
        );

        map.current.setZoom(selectorRedux.boundsLeft?.zoom);

        mapSecondary.current.fitBounds(
          L.latLngBounds(
            selectorRedux.boundsRight?._southWest,
            selectorRedux.boundsRight?._northEast
          )
        );
        mapSecondary.current.setZoom(selectorRedux.boundsRight?.zoom);
      } else {
        map.current.fitBounds(geomGeoJSON.getBounds());
        mapSecondary.current.fitBounds(geomGeoJSONSecondary.getBounds());
      }
    } else {
      map.current.fitBounds(
        L.latLngBounds(
          selectorRedux.boundsLeft?._southWest,
          selectorRedux.boundsLeft?._northEast
        )
      );

      map.current.setZoom(selectorRedux.boundsLeft?.zoom);

      mapSecondary.current.fitBounds(
        L.latLngBounds(
          selectorRedux.boundsRight?._southWest,
          selectorRedux.boundsRight?._northEast
        )
      );

      mapSecondary.current.setZoom(selectorRedux.boundsRight?.zoom);
    }
  }, [geom]);

  return (
    <>
      <GlobalStyles
        styles={{
          '.leaflet-control-zoom-in, .leaflet-control-zoom-out': {
            border: '0 !important',
          },
          '.easy-button-button': {
            width: 30,
            height: 30,
            padding: 0,
            border: 0,
          },
          '.easy-button-button svg': {
            fontSize: 20,
          },
        }}
      />

      <Box
        height="inherit"
        position="relative"
        display="grid"
        gridTemplateColumns="1fr 1fr"
        gap={printMode ? '3mm' : 0}
      >
        <Box position="relative" height="100%">
          <Box id="map" style={{ height: '100%', border: '2px solid black' }} />

          {selectorRedux.loadingLeft && (
            <Box
              position="absolute"
              top={0}
              right={0}
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              zIndex={9999}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
        <Box position="relative" height="100%">
          <Box
            id="mapSecondary"
            style={{ height: '100%', border: '2px solid black' }}
          />
          {selectorRedux.loadingRight && (
            <Box
              position="absolute"
              top={0}
              right={0}
              width="100%"
              height="100%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              zIndex={9999}
            >
              <CircularProgress />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default MapValidationComponent;
