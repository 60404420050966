const COLORS = {
  '2008': '#E87722',
  '2009': '#4E137D',
  '2010': '#00D7BE',
  '2011': '#EDA778',
  '2012': '#892E64',
  '2013': '#AFBCC3',
  '2014': '#F8B0E9',
  '2015': '#888B35',
  '2016': '#9B9DFE',
  '2017': '#FEF3AB',
  '2018': '#DE1E7A',
  '2019': '#E87722',
  '2020': '#4E137D',
  '2021': '#00D7BE',
  '2022': '#EDA778',
  '2023': '#892E64',
  '2024': '#AFBCC3',
} as const;

const OPACITY_COLORS = {
  '2008': '#E877224D',
  '2009': '#4E137D4D',
  '2010': '#00D7BE4D',
  '2011': '#EDA7784D',
  '2012': '#892E644D',
  '2013': '#AFBCC34D',
  '2014': '#F8B0E94D',
  '2015': '#888B354D',
  '2016': '#9B9DFE4D',
  '2017': '#FEF3AB4D',
  '2018': '#DE1E7A4D',
  '2019': '#E87722',
  '2020': '#4E137D',
  '2021': '#00D7BE',
  '2022': '#EDA778',
  '2023': '#892E64',
  '2024': '#AFBCC3',
};

export default function getStyleByYear(year: string) {
  return {
    color: COLORS[year] || 'black',
    bgOpacity: OPACITY_COLORS[year],
    fillOpacity: Number(year) >= 2019 ? 1 : 0.3,
    stroke: Number(year) >= 2019 ? false : true,
  };
}
